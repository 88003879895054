import React from 'react';

const en = {
    // ACCOUNT
    account_header: 'My Account',
    account_chances: 'Total Points', //changed
    account_level: 'Level',
    account_name: 'Name',
    account_edit: 'Edit',
    account_language: 'Language',
    account_language_value: 'English',
    account_bronze: 'Bronze',
    account_silver: 'Silver',
    account_gold: 'Gold',
    account_platinum: 'Platinum',
    account_diamond: 'Diamond',
    account_language_change: 'Language Changed Successfully.',
    account_name_submit: 'Name Changed Successfully.',
    dialogue_title: 'Confirm Language Change',
    dialogue_content: 'Are you sure you wish to change language?',
    dialogue_cancel: 'Cancel',
    dialogue_confirm: 'Confirm',
    edit_name_header: 'Name',
    edit_name_cancel: 'Cancel',
    edit_name_submit: 'Submit',
    account_hello: 'Hello', //changed
    statistics: 'Statistics', //changed
    total_correct_answers: 'Total Correct Answers', //changed
    account_settings: 'User Settings', //changed
    account_settings_last_updated: 'Last Updated', //changed
    contact_us: 'Contact us', //changed
    change_pic: 'Change',//changed
    rename: 'Rename',//changed
    get_help: 'Get Help',//changed
    unsub_message_hello : 'Hey',//changed
    unsub_message : 'If you want to opt out from Quiz reminders click below:',//changed
    how_to_play_terms:'The service is available only for',//changed
    subscribers: 'subscribers',//changed
    win_big_with: 'Win Big with', //changed
    // MENU
    menu_how_to: 'How to Play',
    menu_prizes: 'Prizes',
    menu_winners: 'Winners',
    menu_faq: 'FAQ',
    menu_terms: 'T&Cs',
    menu_privacy: 'Privacy Policy',
    menu_account: 'My Account',
    // QUESTIONS
    question_correct: 'You Answered Correctly!',
    question_wrong: 'Wrong Answer',
    no_questions: "You have answered today's package! We will be waiting for you with a new package to collect chances for the draws!",
    question_header: 'Question {question_index} of {question_total}',
    question_chances: 'For {chance_number} Chances',
    // WELCOME
    welcome_header: 'Change your life',
    welcome_wait: 'Please wait...',
    // SHORTCODES
    shortcode_header: 'Make your dreams come true',
    shortcode_instructions: <span> To subscribe, send an SMS with the word<span className='orange'> JOIN </span>to:</span>,
    shortcode_state: 'State',
    shortcode_provider: 'Provider',
    shortcode_number: 'Number',
    shortcode_footer_1: 'Already subscribed?',
    shortcode_footer_2: 'Use the link you received to access your profile',
    // MESSAGES
    server_error: 'Internal Server Error',
    network_error: 'Failed to Contact Server',
    login_error: 'There was an error communicating with the server. Check your internet connection and try again.',
    login_try_again: 'TRY AGAIN',
    no_auth: 'Failed to authenticate',
    invalid_pin: 'Invalid PIN. Please enter the correct one or request a new one.',
    name_too_large: 'Please enter a shorter username.',
    // HOW TO PLAY
    how_to_play_1: 'A number of chances and a link to a game of 3 questions are sent to you upon subscribing and every time your subscription is renewed.',
    how_to_play_2: 'By answering correctly to the game questions, you can increase your chances in the draws.',
    // PRIZES
    dream_prizes: ' $3,000,000 in total for this year, to make more dreams come true.',
    // WINNERS
}

export default en;
